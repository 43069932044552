export var findWallet = function findWallet(_ref) {
  var chainId = _ref.chainId,
      account = _ref.account;
  return function (wallet) {
    var _wallet$address;

    return JSON.stringify({
      chainId: wallet.chainID,
      address: (_wallet$address = wallet.address) === null || _wallet$address === void 0 ? void 0 : _wallet$address.toLowerCase()
    }) === JSON.stringify({
      chainId: chainId,
      address: account === null || account === void 0 ? void 0 : account.toLowerCase()
    });
  };
};